import { Icon } from 'ui-kit-euroopt';
import cls from '../Footer/index.module.scss';
import { openCookiesModal } from '../modal/cookies';

const results = [
	{
		title: 'Результаты розыгрышей по рекламной игре «Е-везунчики. Курс на деньги!» (15.01-11.03.2025)',
		href: '/assets/files/winners_curs_to_money.pdf',
	},
	{
		title: 'Результаты розыгрышей по рекламной игре «Е-везунчики. По машинам!» (16.10–10.12.2024)',
		href: '/assets/files/winners-by-cars4.pdf',
	},
	{
		title: 'Результаты розыгрышей по рекламной игре «Е-везунчики. На домашнем!» (14.08–08.10.2024)',
		href: '/assets/files/winners-at-home4.pdf',
	},
	{
		title: 'Результаты розыгрышей по рекламной игре «Е-везунчики. На чиле!» (12.06–06.08.2024)',
		href: '/assets/files/winners-on-chill5.pdf',
	},
	{
		title: 'Результаты розыгрышей по рекламной игре «Е-везунчики. На драйве!» (10.04–04.06.2024)',
		href: '/assets/files/winners-on-drive2.pdf',
	},
	{
		title: 'Результаты розыгрышей по рекламной игре «Е-везунчики. Это любовь!» (17.01–12.03.2024)',
		href: '/assets/files/winners-love.pdf',
	},
	{
		title: 'Результаты розыгрышей по рекламной игре «Е-везунчики. Удваиваемся!» (28.09 – 22.11.2023)',
		href: '/assets/files/winners-doubling.pdf',
	},
];

const Footer = () => {
	return (
		<footer id="footer" className={cls.footer}>
			<div className={cls.footer__wrapper}>
				{results.map(({ title, href }, index) => (
					<div className={cls.footer__results} key={index}>
						<a href={href} target="_blank" rel="noreferrer">
							{title} <Icon name="chevron-12" />
						</a>
					</div>
				))}
				<div className={cls.footer__copyright}>
					Рекламная игра «Е-везунчики. Летим домой!» Срок проведения с 19.03.2025 по 16.07.2025 (включая период выдачи
					призов и публикации результатов). Свидетельство о регистрации №4471 от 24.09.2024 года, выданное Министерством
					антимонопольного регулирования и торговли Республики Беларусь. ООО «ЭфСиБи Бел», УНП 193185741.
				</div>
				<button className={cls.cookie} onClick={() => openCookiesModal()}>
					Настройка согласия на файлы cookie
				</button>
			</div>
		</footer>
	);
};

export default Footer;
