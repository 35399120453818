import classNames from "classnames";
import {
  type CSSProperties,
  forwardRef,
  type PropsWithChildren,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";

import styles from "./index.module.scss";

interface BodyProps {
  withFooter?: boolean;
  style?: CSSProperties;
  mix?: string;
  classes?: {
    container?: string;
    content?: string;
  };
}

export function useMergedRef<T>(...refs: React.Ref<T>[]): React.RefCallback<T> {
  return useCallback(
    (element: T) => {
      refs.forEach((ref) => {
        if (typeof ref === "function") {
          ref(element);
        } else if (ref && typeof ref === "object") {
          (ref as React.MutableRefObject<T | null>).current = element;
        }
      });
    },
    [refs]
  );
}

export const Body = forwardRef<HTMLDivElement, PropsWithChildren<BodyProps>>(
  ({ children, mix, style, withFooter, classes = {} }, ref) => {
    const bodyRef = useRef<HTMLDivElement>(null);
    const mergedRef = useMergedRef<HTMLDivElement>(ref, bodyRef);

    const [isScrolled, setIsScrolled] = useState(false);

    const calculate = (element: HTMLDivElement) => {
      const parent = element.parentElement;

      if (parent) {
        const isScrolled = !!element.scrollTop;
        const isHaveScroll = !!element.scrollHeight;
        const isScrolledToEnd =
          element.offsetHeight + element.scrollTop >= element.scrollHeight;

        const header =
          parent.querySelector<HTMLDivElement>('[class^="header"]');
        const footer =
          parent.querySelector<HTMLDivElement>('[class^="footer"]');

        setIsScrolled(isScrolled);

        if (header) {
          if (isScrolled) {
            header.style.setProperty(
              "box-shadow",
              "0px 12px 20px 0px rgb(28 28 30 / 3%)"
            );
          } else {
            header.style.removeProperty("box-shadow");
          }
        }

        if (footer) {
          if (isHaveScroll) {
            footer.style.setProperty(
              "box-shadow",
              "0px -12px 20px 0px rgb(28 28 30 / 3%)"
            );
          }

          if (isScrolledToEnd) {
            footer.style.removeProperty("box-shadow");
          }
        }
      }
    };

    useEffect(() => {
      const element = bodyRef.current;

      if (element) {
        calculate(element);
      }
    }, [children]);

    return (
      <div
        ref={mergedRef}
        style={style}
        onScroll={(e) => calculate(e.target as HTMLDivElement)}
        className={classNames(styles.body, classes.container, mix, {
          [styles.body_scrolled]: isScrolled,
        })}
      >
        <div
          className={classNames(styles.content, classes.content, {
            [styles.content_with_footer]: withFooter,
          })}
        >
          {children}
        </div>
      </div>
    );
  }
);

Body.displayName = "Body";
