import { Button, Toggle } from "ui-kit-euroopt";
import { type FC, useEffect, useState } from "react";

import { asyncModal } from "../../../helpers/asyncModal";
import { Modal } from "../../modal-components-new";
import { useClientCookies } from "./hooks";

import styles from "./index.module.scss";
import classNames from "classnames";

interface CookiesModalProps {
	resolve(result: boolean): void;
}

const CookiesModal: FC<CookiesModalProps> = ({ resolve }) => {
	const [settings, setSettings] = useState(false);
	const [checked, setChecked] = useState(false);

	const { areCookiesAccepted, enable, disable } = useClientCookies();

	const fillChecked = (): void => {
		setChecked(areCookiesAccepted);
	};

	const acceptAll = (): void => {
		enable();
		resolve(true);
	};

	const acceptFromSettings = (): void => {
		if (checked) {
			enable();
		} else {
			disable();
		}

		resolve(checked);
	};

	const cancel = (): void => {
		resolve(false);

		setTimeout(() => {
			openCookiesModal("cancel");
		}, 300);
	};

	useEffect(() => {
		fillChecked();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Modal.Header>
				<Modal.Title id="cookies-modal-title">
					{settings ? "Настройка файлов cookie" : "Файлы cookie 💕🍪"}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body withFooter>
				<div className={styles.body}>
					{settings ? (
						<>
							<div className={styles.toggle_body}>
								<h2 className={styles.toggle_title}>
									Системные файлы cookie
									<Toggle disabled checked />
								</h2>
								<p className={styles.toggle_content}>
									Эти файлы необходимы для функционирования сайта и не могут быть
									отключены в наших системах. Вы можете настроить браузер так, чтобы он
									блокировал их или уведомлял вас об их использовании, но в таком случае
									возможно, что некоторые разделы сайта не будут работать
								</p>
							</div>
							<div className={styles.toggle_body}>
								<h2 className={styles.toggle_title}>
									Другие файлы cookie
									<Toggle checked={checked} onChange={setChecked} />
								</h2>
								<p className={styles.toggle_content}>
									Эти файлы cookie используются нами в статистических целях и могут
									использоваться для сбора данных о ваших интересах, посещаемых страницах
									и источниках трафика, что помогает нам улучшать работу нашего сайта,
									сделать его более удобным для использования.
								</p>
								<p className={styles.toggle_notice}>
									Отключение этих cookie-файлов не позволит принимать меры по
									совершенствованию работы Сайта исходя из предпочтений пользователей.
								</p>
							</div>
						</>
					) : (
						<p className={styles.body_title}>
							Для корректной работы сайта и вашего удобства мы используем файлы cookie
						</p>
					)}

					<p className={styles.body_content}>
						Нажимая «Принять», вы даёте согласие на обработку файлов cookie в
						соответствии с{" "}
						<a
							href="https://api-preprod.emall.by/Cookies.pdf"
							target="_blank"
							className={styles.more}
							rel="noreferrer"
						>
							Политикой обработки файлов cookie
						</a>
					</p>

					{!settings && (
						<button className={styles.settings} onClick={() => setSettings(true)}>
							Настроить
						</button>
					)}
				</div>
			</Modal.Body>

			<Modal.Footer mix={styles.footer}>
				{settings ? (
					<Button
						size="medium"
						type="subtle"
						onClick={() => {
							setSettings(false);
							fillChecked();
						}}
						mix={classNames(styles.button, styles.deny)}
					>
						Назад
					</Button>
				) : (
					<Button
						size="medium"
						type="subtle"
						onClick={cancel}
						mix={classNames(styles.button, styles.deny)}
					>
						Отклонить
					</Button>
				)}
				<Button
					size="medium"
					onClick={settings ? acceptFromSettings : acceptAll}
					mix={styles.button}
				>
					Принять
				</Button>
			</Modal.Footer>
		</>
	);
};

const CancelCookiesModal: FC<CookiesModalProps> = ({ resolve }) => {
	const { enable, disable } = useClientCookies();

	const cancel = (): void => {
		disable();
		resolve(false);
	};

	const acceptAll = (): void => {
		enable();
		resolve(true);
	};

	return (
		<>
			<Modal.Header>
				<Modal.Title id="cookies-modal-title">Отклонение cookie файлов</Modal.Title>
			</Modal.Header>
			<Modal.Body withFooter>
				<div className={styles.body}>
					<p className={styles.body_title}>
						Вы собираетесь отключить использование cookies. Файлы cookies делают ваше
						взаимодействие с сайтом более удобным и безопасным, а нам позволяют
						персонализировать для вас рекламу, скидки и иные маркетинговые предложения
					</p>
				</div>
			</Modal.Body>
			<Modal.Footer mix={styles.footer}>
				<Button
					size="medium"
					type="subtle"
					onClick={cancel}
					mix={classNames(styles.button, styles.deny)}
				>
					Отклонить
				</Button>
				<Button size="medium" onClick={acceptAll} mix={styles.button}>
					Принять
				</Button>
			</Modal.Footer>
		</>
	);
};

export const openCookiesModal = (
	mode?: "cancel" | "default"
): Promise<boolean> =>
	asyncModal(
		mode === "cancel" ? CancelCookiesModal : CookiesModal,
		{},
		{
			closeOnEsc: false,
			closeOnRouteChange: false,
			showCloseIcon: false,
			closeOnOverlayClick: false,
			ariaLabelledby: "cookies-modal-title",
			classNames: {
				modal: styles.modal,
			},
		}
	);
