import { Loader as Spinner } from "../loader";
import classNames from "classnames";
import React, { FC, ReactElement, TouchEvent, useEffect, useRef } from "react";
import { Button, Icon } from "ui-kit-euroopt";

import { Error } from "../error";
import styles from "./index.module.scss";

const Header: FC<{
  mix?: string;
  description?: string;
  noShadow?: boolean;
  children?: string | JSX.Element | JSX.Element[];
}> = ({ description, mix, children, noShadow }) => (
  <div
    className={classNames(styles.header, mix, {
      [styles.header_shadow]: !noShadow,
    })}
  >
    <span className={styles.header__title}>{children}</span>
    {description ? (
      <span className={styles.header__description}>{description}</span>
    ) : null}
  </div>
);

const Close: FC<{ onClose?: () => unknown; mix?: string; size?: number }> = ({
  onClose,
  mix,
  size = 24,
}) => {
  return (
    <div
      onClick={onClose}
      className={classNames(styles.close, mix)}
      title="Закрыть"
    >
      <Icon size={size} name="close-20" />
    </div>
  );
};

const Body: FC<{
  mix?: string;
  children?: string | JSX.Element | JSX.Element[];
}> = ({ children, mix }) => (
  <div className={classNames(styles.body, mix)}>{children}</div>
);

const Loader: FC = () => (
  <div className={styles.loader}>
    <Spinner delay={0} />
  </div>
);

interface ISwiper {
  onSwipeBottom?: () => void;
  distance?: number;
}

let touchStart = 0;
let touchEnd = 0;
let difference = 0;
let modalElement: HTMLDivElement | null | undefined = null;

const handleTouchStart = (e: TouchEvent<HTMLDivElement>): void => {
  touchStart = e.targetTouches[0].clientY;
};

const handleTouchMove = (e: TouchEvent<HTMLDivElement>): void => {
  touchEnd = e.targetTouches[0].clientY;
  difference = touchStart - touchEnd;

  if (modalElement && difference < 0) {
    modalElement.style.bottom = `${difference}px`;
  }
};

const Swiper: FC<ISwiper> = ({ distance = 50, onSwipeBottom }) => {
  const swiper = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    modalElement = swiper.current?.closest(".react-responsive-modal-modal");
  }, []);

  const handleTouchEnd = (): void => {
    if (onSwipeBottom && touchStart - touchEnd < -distance) {
      onSwipeBottom();
    } else if (modalElement && -difference < distance) {
      modalElement.style.transition = "bottom .5s";
      modalElement.style.bottom = "0px";
      // @ts-ignore
      setTimeout(() => (modalElement.style.transition = ""), 500);
    }
  };

  return (
    <div
      ref={swiper}
      className={styles.swiper}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
    />
  );
};

interface IActions {
  mode?: "short" | "full" | "left" | "single" | "center";
  acceptHtmlType?: "button" | "submit";
  acceptText?: ReactElement | string;
  acceptLoading?: boolean;
  cancelText?: string;
  acceptDisabled?: boolean;
  hideCancel?: boolean;
  onAccept?: () => unknown;
  onCancel?: () => unknown;
  mix?: string;
  error?: {
    title?: string;
    message: string;
    mix?: string;
  } | null;
}

const Actions: FC<IActions> = ({
  acceptHtmlType = "button",
  acceptText = "Подтвердить",
  acceptLoading = false,
  acceptDisabled = false,
  cancelText = "Отмена",
  onCancel,
  onAccept,
  hideCancel = false,
  mix,
  mode = "short",
  error,
}) => {
  return (
    <div
      className={classNames(styles.actions, mix, {
        [styles.actions_short]: mode === "short",
        [styles.actions_full]: mode === "full",
        [styles.actions_left]: mode === "left",
        [styles.actions_single]: mode === "single",
        [styles.actions_center]: mode === "center",
      })}
    >
      {error?.message && (
        <Error
          title={error?.title || ""}
          message={error?.message}
          mix={classNames(styles.actions_error, error?.mix)}
        />
      )}
      <div className={styles.actions_buttons}>
        {hideCancel ? null : (
          <Button
            mix={classNames(
              styles.actions__button,
              styles.actions__button_cancel
            )}
            size="large"
            type="subtle"
            block
            onClick={onCancel}
          >
            {cancelText}
          </Button>
        )}
        <Button
          htmlType={acceptHtmlType}
          disabled={acceptDisabled}
          mix={classNames(
            styles.actions__button,
            styles.actions__button_accept,
            {
              [styles.actions__button_disabled]: acceptDisabled,
            }
          )}
          size="large"
          block
          onClick={onAccept}
          loading={acceptLoading}
          withFeedBack={false}
        >
          {acceptText}
        </Button>
      </div>
    </div>
  );
};

const Title: FC<{
  id?: string;
  mix?: string;
  children?: string | JSX.Element | JSX.Element[];
}> = ({ id, children, mix }) => (
  <h3 id={id} className={classNames(styles.title, mix)}>{children}</h3>
);

export const Modal = {
  Header,
  Body,
  Loader,
  Swiper,
  Actions,
  Title,
  Close,
};
