export const BRANDS = {
    edostavka: [
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B1003%5D%7D',
            icon: '/assets/images/brands/1.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B378%5D%7D',
            icon: '/assets/images/brands/2.webp',
        },
        {
            url: '',
            icon: '/assets/images/brands/3.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B1532%5D%7D',
            icon: '/assets/images/brands/4.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B22214%5D%7D',
            icon: '/assets/images/brands/5.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B38722%5D%7D',
            icon: '/assets/images/brands/6.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B28647%5D%7D',
            icon: '/assets/images/brands/7.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B560%5D%7D',
            icon: '/assets/images/brands/8.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B38904%5D%7D',
            icon: '/assets/images/brands/9.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B2792%5D%7D',
            icon: '/assets/images/brands/10.webp',
        },
        {
            url: '',
            icon: '/assets/images/brands/11.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B45059%5D%7D',
            icon: '/assets/images/brands/12.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B2585%5D%7D',
            icon: '/assets/images/brands/13.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B1204%5D%7D',
            icon: '/assets/images/brands/14.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B28284%5D%7D',
            icon: '/assets/images/brands/15.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B1239%5D%7D',
            icon: '/assets/images/brands/16.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B2748%5D%7D',
            icon: '/assets/images/brands/17.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B331%5D%7D',
            icon: '/assets/images/brands/18.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B945%5D%7D',
            icon: '/assets/images/brands/19.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B9650%5D%7D',
            icon: '/assets/images/brands/20.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B36130%5D%7D',
            icon: '/assets/images/brands/21.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B9238%5D%7D',
            icon: '/assets/images/brands/22.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B777%5D%7D',
            icon: '/assets/images/brands/23.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B35679%5D%7D',
            icon: '/assets/images/brands/24.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B27427%5D%7D',
            icon: '/assets/images/brands/25.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B39200%5D%7D',
            icon: '/assets/images/brands/26.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B11433%5D%7D',
            icon: '/assets/images/brands/27.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B1209%5D%7D',
            icon: '/assets/images/brands/28.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B28650%5D%7D',
            icon: '/assets/images/brands/29.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B355%5D%7D',
            icon: '/assets/images/brands/30.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B330%5D%7D',
            icon: '/assets/images/brands/31.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B36993%5D%7D',
            icon: '/assets/images/brands/32.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B467%5D%7D',
            icon: '/assets/images/brands/33.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B10198%5D%7D',
            icon: '/assets/images/brands/34.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B650%5D%7D',
            icon: '/assets/images/brands/35.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B41880%5D%7D',
            icon: '/assets/images/brands/36.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B2787%5D%7D',
            icon: '/assets/images/brands/37.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B354%5D%7D',
            icon: '/assets/images/brands/38.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B8569%5D%7D',
            icon: '/assets/images/brands/39.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B38917%5D%7D',
            icon: '/assets/images/brands/40.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B740%5D%7D',
            icon: '/assets/images/brands/41.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B2888%5D%7D',
            icon: '/assets/images/brands/42.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B45036%5D%7D',
            icon: '/assets/images/brands/43.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B836%5D%7D',
            icon: '/assets/images/brands/44.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B42913%5D%7D',
            icon: '/assets/images/brands/45.webp?v=2',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B618%5D%7D',
            icon: '/assets/images/brands/46.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B26943%5D%7D',
            icon: '/assets/images/brands/47.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B42580%5D%7D',
            icon: '/assets/images/brands/48.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B42582%5D%7D',
            icon: '/assets/images/brands/49.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B447%5D%7D',
            icon: '/assets/images/brands/50.webp',
        },
        {
            url: 'https://edostavka.by/actions/lucky-goods?lc=2&filter=%7B%22TrademarkId%22%3A%5B2316%5D%7D',
            icon: '/assets/images/brands/51.webp',
        },
    ],
    emall: [
        {
            url: 'https://emall.by/actions/lucky-goods?brands=1003',
            icon: '/assets/images/brands/1.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=378',
            icon: '/assets/images/brands/2.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=41819',
            icon: '/assets/images/brands/3.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=1532',
            icon: '/assets/images/brands/4.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=22214',
            icon: '/assets/images/brands/5.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=28647',
            icon: '/assets/images/brands/7.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=560',
            icon: '/assets/images/brands/8.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=38904',
            icon: '/assets/images/brands/9.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=2792',
            icon: '/assets/images/brands/10.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=22244',
            icon: '/assets/images/brands/11.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=45059',
            icon: '/assets/images/brands/12.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=2585',
            icon: '/assets/images/brands/13.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=1204',
            icon: '/assets/images/brands/14.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=28284',
            icon: '/assets/images/brands/15.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=1239',
            icon: '/assets/images/brands/16.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=2748',
            icon: '/assets/images/brands/17.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=331',
            icon: '/assets/images/brands/18.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=945',
            icon: '/assets/images/brands/19.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=36130',
            icon: '/assets/images/brands/21.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=9238',
            icon: '/assets/images/brands/22.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=777',
            icon: '/assets/images/brands/23.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=35679',
            icon: '/assets/images/brands/24.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=27427',
            icon: '/assets/images/brands/25.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=39200',
            icon: '/assets/images/brands/26.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=11433',
            icon: '/assets/images/brands/27.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=1209',
            icon: '/assets/images/brands/28.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=28650',
            icon: '/assets/images/brands/29.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=355',
            icon: '/assets/images/brands/30.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=330',
            icon: '/assets/images/brands/31.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=36993',
            icon: '/assets/images/brands/32.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=467',
            icon: '/assets/images/brands/33.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=10198',
            icon: '/assets/images/brands/34.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=650',
            icon: '/assets/images/brands/35.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=41880',
            icon: '/assets/images/brands/36.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=2787',
            icon: '/assets/images/brands/37.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=354',
            icon: '/assets/images/brands/38.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=8569',
            icon: '/assets/images/brands/39.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=38917',
            icon: '/assets/images/brands/40.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=740',
            icon: '/assets/images/brands/41.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=2888',
            icon: '/assets/images/brands/42.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=45036',
            icon: '/assets/images/brands/43.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=836',
            icon: '/assets/images/brands/44.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=618',
            icon: '/assets/images/brands/46.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=26943',
            icon: '/assets/images/brands/47.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=42580',
            icon: '/assets/images/brands/48.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=42582',
            icon: '/assets/images/brands/49.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=447',
            icon: '/assets/images/brands/50.webp',
        },
        {
            url: 'https://emall.by/actions/lucky-goods?brands=2316',
            icon: '/assets/images/brands/51.webp',
        },
    ]
}