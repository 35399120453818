import classNames from "classnames";
import type { CSSProperties, FC, PropsWithChildren } from "react";

import styles from "./index.module.scss";

interface HeaderProps {
  style?: CSSProperties;
  mix?: string;
}

export const Header: FC<PropsWithChildren<HeaderProps>> = ({
  children,
  mix,
  style,
}) => {
  return (
    <div style={style} className={classNames(styles.header, mix)}>
      {children}
    </div>
  );
};
