import {useState} from "react";
import cls from "../Body/index.module.scss";
import clsx from "clsx";
import classNames from "classnames";
import {BRANDS} from "./constants";

export const Brands = () => {
    const [activeTab, setActiveTab] = useState<'emall' | 'edostavka'>('emall');
    const arr = activeTab === 'emall' ? BRANDS.emall : BRANDS.edostavka;

    const promoUrl =
        activeTab === 'emall' ? 'https://emall.by/actions/lucky-goods' : 'https://edostavka.by/actions/lucky-goods ';

    return (
        <div className={cls.brands}>
            <div className={cls.tabs}>
				<span
                    onClick={() => setActiveTab('emall')}
                    style={activeTab === 'emall' ? { color: '#F4303B', borderColor: '#F4303B' } : undefined}
                    className={clsx(cls.tabs__tab, cls.tabs__emall, { [cls.tabs__tab_active]: activeTab === 'emall' })}
                >
					emall
				</span>
                <span
                    onClick={() => setActiveTab('edostavka')}
                    style={activeTab === 'edostavka' ? { color: '#02B875', borderColor: '#02B875' } : undefined}
                    className={clsx(cls.tabs__tab, cls.tabs__edostavka, { [cls.tabs__tab_active]: activeTab === 'edostavka' })}
                >
					edostavka
				</span>
            </div>
            <div
                className={classNames(cls.brands__block, {
                    [cls.blocks_emall]: activeTab === 'emall',
                    [cls.blocks_edostavka]: activeTab === 'edostavka',
                })}
            >
                {arr.map(({ url, icon }, index) => (
                    <a key={icon} target="_blank" href={url || promoUrl} rel="noreferrer">
                        <div className={cls.brands__card}>
                            <img src={icon} alt={`Бренд №${index + 1}`} />
                        </div>
                    </a>
                ))}
            </div>
            <a className={cls.brands__link} target="_blank" href={promoUrl} rel="noreferrer">
                <span>Смотреть все товары</span>
            </a>
        </div>
    );
};
