import Body from './components/Body';
import Footer from './components/Footer';
import Header from './components/Header';
import { useAnalytics } from './hooks/useAnalytics';


const App = () => {
  useAnalytics();
  
  return (
    <>
      <Header/>
      <Body/>
      <Footer/>
    </>
  );
}

export default App;
