import { useEffect } from "react";
import { openCookiesModal } from "../components/modal/cookies";
import { embedAnalytics } from "../components/modal/cookies/utils";

export const useAnalytics = () => {
	useEffect(() => {
		const areCookiesRejected = localStorage.getItem("cookies-rejected");

		if (areCookiesRejected) {
			return;
		}

		const areCookiesAccepted = localStorage.getItem("cookies-accepted");

		let timeout: ReturnType<typeof setTimeout>;

		if (areCookiesAccepted) {
			embedAnalytics();
		} else {
			timeout = setTimeout(openCookiesModal, 1000);
		}

		return () => {
			clearTimeout(timeout);
		};
	}, []);
};
