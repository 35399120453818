import classNames from "classnames";
import type { FC, PropsWithChildren } from "react";

import styles from "./index.module.scss";

interface FooterProps {
  mix?: string;
}

export const Footer: FC<PropsWithChildren<FooterProps>> = ({
  children,
  mix,
}) => {
  return <div className={classNames(styles.footer, mix)}>{children}</div>;
};
