import classNames from "classnames";
import type { ElementType, FC, PropsWithChildren } from "react";

import styles from "./index.module.scss";

interface TitleProps {
  onClick?: () => void;
  as?: ElementType;
  id?: string;
  mix?: string;
}

const DEFAULT_ELEMENT = "h4";

export const Title: FC<PropsWithChildren<TitleProps>> = ({
  children,
  onClick,
  as,
  id,
  mix,
}) => {
  const Component = as || DEFAULT_ELEMENT;

  return (
    <Component
      onClick={onClick}
      className={classNames(styles.title, mix)}
      id={id}
    >
      {children}
    </Component>
  );
};
