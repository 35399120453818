import clsx from 'clsx';
import { hyphenateSync } from 'hyphen/ru';
import moment from 'moment';
import { FC, Fragment, useEffect, useRef, useState } from 'react';
import { Expander, Icon } from 'ui-kit-euroopt';
import { request } from '../../helpers/request';
import { Adaptive } from '../adaptive';
import { Arrow } from '../arrow';
import cls from '../Body/index.module.scss';
import { Container } from '../container';
import { ContainerFluid } from '../container-fluid';
import { openFieldModal } from '../modal/filter';
import classNames from 'classnames';
import {Brands} from "../brands";

interface IBlock {
	name: string | JSX.Element;
	children?: string | JSX.Element | JSX.Element[];
	mix?: string;
	id?: string;
}

const Block: FC<IBlock> = ({ name, children, mix, id }) => {
	return (
		<section id={id} className={clsx(cls.block, mix)}>
			<h2 className={cls.block__name}>{name}</h2>
			<div className={cls.block__body}>{children}</div>
		</section>
	);
};

interface IDrawsAndWinnersCard {
	idx: number;
	date: string;
	list: IList[];
	listEd?: IList[];
	superDraws?: boolean;
	mainDraw?: boolean;
	winner?: boolean;
}

const PrizesInDrawsAndWinnersCard = () => (
	<>
		{' '}
		<span>5 денежных призов по 1000 р.</span>
	</>
);

const DrawsAndWinnersCard: FC<IDrawsAndWinnersCard> = ({ idx, date, superDraws, mainDraw, winner, list, listEd }) => {
	return (
		<div
			className={clsx(cls.card, superDraws && cls.card__superDraws, winner && cls.card__winner)}
			onClick={() => winner && openFieldModal(list, idx, listEd)}
		>
			<div className={cls.card__title}>
				<span className={cls.card__title__left}>{mainDraw ? 'Главный розыгрыш' : `${idx} неделя`}</span>
				{winner ? (
					<span className={cls.card__title__rigth__blue}>
						Результаты <Icon name="chevron-12" />
					</span>
				) : (
					<span className={cls.card__title__rigth}>{date}</span>
				)}
			</div>
			<div className={cls.card__body}>
				{!superDraws && !mainDraw && <PrizesInDrawsAndWinnersCard />}
				{superDraws && <span className={cls.card__body__super}>1 денежный приз 10 000 р.</span>}
				{mainDraw && <span className={cls.card__body__super}>Квартира в Минске</span>}
			</div>
		</div>
	);
};

interface IDrawsAndWinners {
	list: IList[];
	listEd: IList[];
}

const DrawsAndWinners: FC<IDrawsAndWinners> = ({ list, listEd }) => {
	const [arrowsIsVisible, setArrowsIsVisible] = useState<{
		left: boolean;
		right: boolean;
	}>({ left: false, right: false });
	const wrapperSingleRef = useRef<null | HTMLDivElement>(null);
	const [isScrolling, setIsScrolling] = useState(false);
	let scrollTimeout: NodeJS.Timeout;

	const checkArrowIsVisible = (double = false): void => {
		const element = wrapperSingleRef.current;
		if (element) {
			if (element.scrollWidth) {
				setArrowsIsVisible({
					left: !!element.scrollLeft,
					right: element.scrollWidth - element.offsetWidth !== element.scrollLeft,
				});
				if (double) {
					setTimeout(() => {
						checkArrowIsVisible();
					}, 300);
				}
			}
		}
	};

	const scrollTo = (direction: 'left' | 'right'): void => {
		const element = wrapperSingleRef.current;

		if (element) {
			const { scrollLeft, children } = element;
			const { width } = children[0].getBoundingClientRect();
			const widthWithPadding = (width + 16) * 3;
			const position = direction === 'right' ? scrollLeft + widthWithPadding : scrollLeft - widthWithPadding;
			element.scroll({ left: position, behavior: 'smooth' });
			setTimeout(() => checkArrowIsVisible(true), 300);
		}
	};

	const handleScroll = () => {
		if (!isScrolling) {
			setIsScrolling(true);
		}

		clearTimeout(scrollTimeout);

		scrollTimeout = setTimeout(() => {
			setIsScrolling(false);

			checkArrowIsVisible();
		}, 500);
	};

	useEffect(() => {
		setTimeout(checkArrowIsVisible, 500);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [wrapperSingleRef.current]);

	const arr = [
		{
			"DrawDate": "2025-03-27T14:00:00",
			"Winners": []
		},
		{
			"DrawDate": "2025-04-03T14:00:00",
			"Winners": []
		},
		{
			"DrawDate": "2025-04-10T14:00:00",
			"Winners": []
		},
		{
			"DrawDate": "2025-04-17T14:00:00",
			"Winners": []
		},
		{
			"DrawDate": "2025-04-24T14:00:00",
			"Winners": []
		},
		{
			"DrawDate": "2025-05-02T14:00:00",
			"Winners": []
		},
		{
			"DrawDate": "2025-05-08T14:00:00",
			"Winners": []
		},
		{
			"DrawDate": "2025-05-15T14:00:00",
			"Winners": []
		},
		{
			"DrawDate": "2025-05-15T15:00:00",
			"Winners": []
		}
	];

	const Logos: FC = () => {
		const actualList = list.length ? list : arr;
		return (
			<Fragment>
				{actualList.map((el, idx) => (
					<DrawsAndWinnersCard
						date={moment(el.DrawDate).format('DD.MM')}
						mainDraw={idx === actualList.length - 1}
						winner={!!el.Winners.length}
						idx={idx + 1}
						key={idx}
						list={actualList}
						listEd={listEd}
					/>
				))}
			</Fragment>
		);
	};

	return (
		<Fragment>
			<Adaptive.Desktop>
				<Container mix={cls.container}>
					{arrowsIsVisible.left ? (
						<>
							<div className={classNames(cls.gradient, cls.gradient_left)} />
							<Arrow onClick={() => scrollTo('left')} size="medium" direction="left" mix={cls.arrow} />
						</>
					) : null}

					<div ref={wrapperSingleRef} onScroll={handleScroll} className={cls.wrapper_single}>
						<Logos />
					</div>

					{arrowsIsVisible.right ? (
						<>
							<div className={classNames(cls.gradient, cls.gradient_right)} />
							<Arrow
								size="medium"
								onClick={() => scrollTo('right')}
								direction="right"
								mix={clsx(cls.arrow, cls.arrow_right)}
							/>
						</>
					) : null}
				</Container>
			</Adaptive.Desktop>
			<Adaptive.Mobile>
				<ContainerFluid mix={cls.wrapper_single}>
					<Logos />
				</ContainerFluid>
			</Adaptive.Mobile>
			<div className={cls.text}>
				Полное наименование призов смотрите в{' '}
				<a target="_blank" href="/rules.pdf">
					правилах
				</a>{' '}
				проведения рекламной игры
			</div>
		</Fragment>
	);
};

const faq = [
	{
		title: 'Какие даты проведения рекламной игры?',
		desc: 'Для участия в рекламной игре приобретайте товары-везунчики в период с 19 марта по 13 мая 2025 года. Обратите внимание, что в последний день игры заказ с игровыми товарами должен быть получен. По заказам, оформленным в период действия игры, но полученным после ее завершения, коды не начисляются.',
	},
	{
		title: 'В каких магазинах необходимо совершать покупки, чтобы стать участником розыгрышей?',
		desc: 'Совершайте покупки на сайтах <a href="https://edostavka.by" target="_blank">edostavka.by</a> и <a href="https://emall.by" target="_blank">emall.by</a>, а также в мобильных приложениях Едоставка и Емолл.',
	},
	{
		title: 'Как стать участником рекламной игры?',
		desc: `Для участия необходимо:
    </br>
    </br>
    <ul>
        <li>достоверно заполнить персональные данные (фамилия, имя, отчество, телефон, email) и дать своё согласие на участие в рекламной игре в личном кабинете на сайте edostavka.by или emall.by;</li>
        <li>совершить покупку товаров-везунчиков на сумму от 2 руб. в период с 10:00:00 19 марта по 23:59:59 13 мая 2025 года.</li>
    </ul>
    `,
	},
	{
		title: 'Как начисляются коды?',
		desc: `Игровые коды начисляются:
    </br>
    </br>
    <ul>
        <li>за каждые 2 руб. стоимости товаров-везунчиков – 1 игровой код;</li>
        <li>на следующий рабочий день после получения заказа (обратите внимание – не после оформления заказа, а после получения).</li>
    </ul>
    </br>
    Проверить наличие игровых кодов можно в личном кабинете на сайтах <a href="https://edostavka.by/advertising-game" target="_blank">edostavka.by</a> и <a href="https://emall.by/advertising-game" target="_blank">emall.by</a> в разделе «Игровые коды». Номера игровых кодов отображаются не позднее чем за сутки до даты проведения розыгрыша.`,
	},
	{
		title: 'Какие товары необходимо купить для участия в рекламной игре?',
		desc: `Для участия в рекламной игре необходимо купить любой товар-везунчик на сумму от 2 руб. Со всеми товарами, участвующими в рекламной игре, можно ознакомиться на сайтах <a href="https://edostavka.by/actions/lucky-goods" target="_blank">edostavka.by</a> и <a href="https://emall.by/actions/lucky-goods" target="_blank">emall.by.</a>`,
	},
	{
		title: 'В розыгрыше каких призов участвуют мои игровые коды?',
		desc: `В рамках рекламной игры Вы можете стать победителем 2 раза: 
    </br>
    </br>
    <ul>
        <li>1 раз в розыгрыше денежного приза на сумму 1000 р.;</li>
        <li>1 раз в розыгрыше главного приза – квартиры в Минске.</li>
    </ul>`,
	},
	{
		title: 'Что делать, если мне не приходит код?',
		desc: `Проверьте:
    </br>
    </br>
    <ul>
  <li>подтверждено ли согласие на участие в рекламной игре;</li>
  <li>правильно ли указаны персональные данные в личном кабинете;</li>
  <li>есть ли в заказе товары-везунчики на сумму от 2 руб.;</li>
  <li>доставлен (получен) ли заказ;</li>
  <li>вышел ли срок начисления кодов, указанный в пункте «Как начисляются коды?».</li>
  </ul>
  </br>Если все пункты соблюдены, но игровой код всё равно не начислен, обращайтесь на инфолинию по телефону: <a href="tel:+375297710991" target="_blank">+375 29 771 09 91</a> (МТС, A1, life:)), 8:30-20:30, пн-вс`,
	},
	{
		title: 'Когда и где проходят розыгрыши?',
		desc: `Розыгрыши будут проходить по адресу г. Минск, пер. Козлова, 25, пом. 22 (7 этаж).</br>
    С графиком проведения розыгрышей можно ознакомиться <span id="scroll">здесь</span>.`,
	},
	{
		title: 'Как я узнаю, что стал победителем?',
		desc: 'В течение 2 рабочих дней после завершения розыгрыша с Вами свяжутся организаторы рекламной игры. Также результаты розыгрыша будут опубликованы на сайте evezunchiki.by и отобразятся в Вашем личном кабинете.',
	},
	{
		title: 'Зачем предоставлять паспортные данные для получения приза?',
		desc: `В соответствии с Правилами рекламной игры предоставляемая информация для получения приза должна соответствовать Персональным данным, которые Участник указал в регистрационной форме в личном кабинете. В случае несоответствия данных приз такому лицу не отправляется.
    <br /> 
    Организатор осуществляет идентификацию и верификацию на основе данных документа, удостоверяющего личность, которыми и являются паспорт/ID-карта/вид на жительство.`,
	},
	{
		title: 'Где и когда можно получить приз?',
		desc: `Полная информация о получении приза указана в п.12 <a href="/rules.pdf" target="_blank">правил рекламной игры</a>`,
	},
	{
		title: 'Не нашли ответ на свой вопрос?',
		desc: `Задайте его по телефону <a href="tel:+375297710991" target="_blank">+375 29 771 09 91</a> (МТС, A1, life:)). Режим работы: 8:30 - 20:30, понедельник-воскресенье.`,
	},
];

const Prizes: FC = () => {
	const prizes = [
		{
			title: 'Квартира в Минске',
			description: 'Главный приз',
			amount: '1 шт',
			img: '/assets/images/prize_home.png',
		},
		{
			title: 'Денежный приз',
			amount: '40 шт',
			img: '/assets/images/prize_money.png',
			description: '1 000 р.',
		},
	];

	return (
		<div className={cls.prizes}>
			{prizes.map((prize, index) => (
				<div className={cls.prizes__card} key={index}>
					{!!prize.img && <img className={cls.prizes__image} src={prize.img} alt={prize.title} />}
					<div>
						{!!prize.description && <div className={cls.prizes__description}>{prize.description}</div>}
						<div className={cls.prizes__title}>{hyphenateSync(prize.title || '', { minWordLength: 7 })}</div>
						<div className={cls.prizes__amount}>{prize.amount}</div>
					</div>
				</div>
			))}
		</div>
	);
};


const Faq: FC<{
	expendValue: boolean;
	setExpendValue: (value: boolean) => void;
}> = ({ expendValue, setExpendValue }) => {
	const [state, setState] = useState(false);
	const [expend, setExpend] = useState<{ [x: string]: boolean }>({});

	function scrollTo() {
		const element = document.getElementById('draws');
		if (element) {
			element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
		}
	}

	useEffect(() => {
		const element = document.getElementById('scroll');

		if (element) {
			element.addEventListener('click', scrollTo);
		}
		return () => {
			if (element) {
				element.removeEventListener('click', scrollTo);
			}
		};
	}, [state]);
	useEffect(() => {
		if (expendValue && !expend[2]) {
			setExpend((prev) => {
				const copy = JSON.parse(JSON.stringify(prev));
				copy[2] = true;
				return copy;
			});
			setExpendValue(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [expendValue, expend]);
	return (
		<div className={cls.faq}>
			{faq.map((el, idx) => (
				<div key={el.title} id={`expander-${idx}`} onClick={() => setState((prev) => !prev)}>
					<Expander
						defaultExpand={false}
						value={!!expend[idx]}
						onChange={(e) =>
							setExpend((prev) => {
								const copy = JSON.parse(JSON.stringify(prev));
								copy[idx] = e;
								return copy;
							})
						}
						title={el.title}
						mix={clsx(cls.expander, idx === faq.length - 1 && cls.expander_margin)}
					>
						<div className={cls.expander__desc} dangerouslySetInnerHTML={{ __html: el.desc }} />
					</Expander>
				</div>
			))}
		</div>
	);
};

const Rules: FC = () => {
	const arr = [
		{
			title: 'Подтверждайте участие',
			desc: (
				<>
					Подтвердите участие в рекламной игре в личном кабинете, проставив согласие
					<br />(
					<a href="https://edostavka.by/profile?ServiceId=32" target="_blank" rel="noreferrer">
						edostavka
					</a>
					,{' '}
					<a href="https://emall.by/profile?ServiceId=32" target="_blank" rel="noreferrer">
						emall
					</a>
					)
				</>
			),
		},
		{
			title: 'Покупайте товары-везунчики',
			desc: (
				<>
					Получайте игровые коды за каждые 2 BYN стоимости товаров-везунчиков. <span onClick={scrollTo}>Подробнее</span>
				</>
			),
		},
		{
			title: 'Участвуйте в розыгрышах',
			desc: 'Розыгрыши проводятся каждую неделю. Главный приз – КВАРТИРА В МИНСКЕ!',
		},
	];

	function scrollTo() {
		const element = document.getElementById('brands');
		if (element) {
			element.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}

	return (
		<div className={cls.rules}>
			<div className={cls.rules__block}>
				{arr.map((el, idx) => (
					<div className={cls.rules__card} key={el.title}>
						<div className={cls.rules__card__badge} data-number={idx + 1} />
						<span className={cls.rules__card__title}>{el.title}</span>
						<span className={cls.rules__card__desc}>{el.desc}</span>
					</div>
				))}
			</div>
			<a className={cls.rules__link} target="_blank" href="/rules.pdf">
				<Icon size={20} name="download" />
				<span>Скачать правила</span>
			</a>
		</div>
	);
};

interface IWinners {
	PrizeType: 1 | 6 | 8;
	PrizeId: number;
	Winner: string;
	GamePrizeDescription: string;
}

export interface IList {
	DrawDate: string;
	Winners: IWinners[];
}

const Body = () => {
	const [expend, setExpend] = useState(false);
	const [list, setList] = useState<IList[]>([]);

	useEffect(() => {
		request
			.post<IList[]>('Game.EcommerceGameWinnersGet', {
				GameGroupId: '53',
				RoundNumber: '1',
			})
			.then((result) => {
				setList(result ?? []);
			})
			.catch((e) => console.log('err', e));
	}, []);

	return (
		<div id="body" className={clsx(cls.body)}>
			<Block name="Правила игры" mix={cls.rules__wrapper}>
				<Rules />
			</Block>
			<Block name="Призы" mix={classNames(cls.noneOverflow, cls.prizes_block)}>
				<Prizes />
			</Block>
			<Block id="draws" name="Розыгрыши и победители">
				<DrawsAndWinners listEd={[]} list={list} />
			</Block>
			<Block id="brands" name="Бренды">
				<Brands />
			</Block>
			<Block mix={classNames(cls.expanderWrap, cls.small)} name="Вопросы">
				<Faq expendValue={expend} setExpendValue={setExpend} />
			</Block>
		</div>
	);
};

export default Body;
