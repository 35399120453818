import { useCallback, useEffect, useState } from "react";
import { embedAnalytics } from "./utils";

export const useClientCookies = () => {
	const [areCookiesAccepted, setAreCookiesAccepted] = useState(
		Boolean(window.localStorage.getItem("cookies-accepted"))
	);

	const [areCookiesRejected, setAreCookiesRejected] = useState(
		Boolean(window.localStorage.getItem("cookies-rejected"))
	);

	const enable = useCallback(() => {
		setAreCookiesAccepted(true);
		setAreCookiesRejected(false);

		window.localStorage.removeItem("cookies-rejected");
		window.localStorage.setItem("cookies-accepted", "true");
	}, []);

	const disable = useCallback(() => {
		window.localStorage.removeItem("cookies-accepted");
		window.localStorage.setItem("cookies-rejected", "true");

		if (areCookiesAccepted) {
			window.location.reload();
		} else {
			setAreCookiesRejected(true);
			setAreCookiesAccepted(false);
		}
	}, [areCookiesAccepted]);

	useEffect(() => {
		if (areCookiesRejected) {
			return;
		}

		if (areCookiesAccepted) {
			embedAnalytics();
		}
	}, [areCookiesAccepted, areCookiesRejected]);

	return {
		areCookiesAccepted,
		enable,
		disable,
	};
};
