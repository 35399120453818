import { Body } from "./components/body";
import { Footer } from "./components/footer";
import { Header } from "./components/header";
import { Title } from "./components/title";

export const Modal = {
  Body,
  Footer,
  Header,
  Title,
};
